// Import all the blocks in front end as well as admin section.

import "./blocks/banner/script";
import "./blocks/btn-group/script";
import "./blocks/featured-in/script";
import "./blocks/feature/script";
import "./blocks/features/script";
import "./blocks/demos/script";
import "./blocks/cards/script";
import "./blocks/section/script";
import "./blocks/accordian/script";
import "./blocks/slider/script";
