// This file will contain the code for accordian block type in gutenberg editor.
// It will be enqueued in both admin and front end.

import './style.scss';
import $ from 'jquery';

$(document).ready(function() {
    $(document).on('click', '.wp-block-wisdm-blocks-accordian__tsection', function () {
        $(this).closest('.wp-block-wisdm-blocks-accordian').toggleClass('is-collapsed');
    });
});
