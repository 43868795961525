// This file will contain the code for slider block type in gutenberg editor.
// It will be enqueued in both admin and front end.

import './style.scss';
import $ from 'jquery';

$(document).ready(function () {

    // Call initalize to generate the pagination dots and add active class.
    initialize();

    // Click Handler for right arrow. Move to the next slide.
    $(document).on('click', '.dashicons-arrow-right-alt2', function() {
        var parent = $(this).closest('.wp-block-wisdm-blocks-slider');
        const el = $(parent).find('.wp-block-wisdm-blocks-slide.active');
        if (el.next().hasClass('wp-block-wisdm-blocks-slide')) {
            const next = (getCurrentSlideCount(parent) + 1) * -100;
            $(parent).find('.wp-block-wisdm-blocks-slide').css('transform', `translateX(${next}%)`);
            el.removeClass('active');
            el.next().addClass('active');
            const pdot = $(parent).find('.wp-block-wisdm-blocks-slider__pagination-dots.active');
            pdot.removeClass('active');
            pdot.next().addClass('active');
        }
    });

    // Click Handler for left arrow. Move to the left slide.
    $(document).on('click', '.dashicons-arrow-left-alt2', function() {
        var parent = $(this).closest('.wp-block-wisdm-blocks-slider');
        const el = $(parent).find('.wp-block-wisdm-blocks-slide.active');
        if (el.prev().hasClass('wp-block-wisdm-blocks-slide')) {
            const prev = (getCurrentSlideCount(parent) - 1) * -100 ;
            $(parent).find('.wp-block-wisdm-blocks-slide').css('transform', `translateX(${prev}%)`);
            el.removeClass('active');
            el.prev().addClass('active');
            const pdot = $(parent).find('.wp-block-wisdm-blocks-slider__pagination-dots.active');
            pdot.removeClass('active');
            pdot.prev().addClass('active');
        }
    });

    // Returns the current active slider number.
    function getCurrentSlideCount(el) {
        var scount = 0;
        $(el).find('.wp-block-wisdm-blocks-slide').each(function (index, el) {
            if ($(el).hasClass('active')) {
                scount = index;
                return false;
            }
        });
        return scount;
    }

    // Generate the pagination dots and add active class to first slide.
    function initialize() {
        $('.wp-block-wisdm-blocks-slider').each(function(index, el) {
            $(el).find('.wp-block-wisdm-blocks-slide').each(function (index) {
                $(el).find('.wp-block-wisdm-blocks-slider__pagination').append(`<div class="wp-block-wisdm-blocks-slider__pagination-dots" data-slide=${index}></div`)
            });
            $(el).find('.wp-block-wisdm-blocks-slide:first').addClass('active');
            $(el).find('.wp-block-wisdm-blocks-slider__pagination-dots:first').addClass("active");
        });
    }

    // Click Handler for pagination dots.
    $(document).on('click', '.wp-block-wisdm-blocks-slider__pagination-dots', function() {
        var parent = $(this).closest('.wp-block-wisdm-blocks-slider');
        const slideCount = $(this).data('slide');
        const activeSlideCount = $(parent).find('.wp-block-wisdm-blocks-slider__pagination-dots.active').data('slide');
        if (slideCount == activeSlideCount) return;
        if (slideCount < activeSlideCount) {
            const prev = slideCount * 100;
            $(parent).find('.wp-block-wisdm-blocks-slide').css('transform', `translateX(${prev}%)`);
        }
        if (slideCount > activeSlideCount) {
            const next = slideCount * -100;
            $(parent).find('.wp-block-wisdm-blocks-slide').css('transform', `translateX(${next}%)`);
        }
        $(parent).find('.wp-block-wisdm-blocks-slider__pagination-dots.active').removeClass('active');
        $(this).addClass('active');
        $(parent).find('.wp-block-wisdm-blocks-slide.active').removeClass('active');
        $(parent).find(`.wp-block-wisdm-blocks-slide:eq(${slideCount})`).addClass('active');
    });
});
